<template>
  <div class="system-view">
    <el-row :gutter="25">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>基地维修工程师设置</span>
          </div>
          <el-row v-for="(item, index) of baseList" :key="index">
            {{item.name}}
            <el-select v-model="item.repairEngineerList" multiple size="mini" style="width: 400px;" :key="'base_' + index">
              <el-option v-for="s in repairEngineerList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
            </el-select>
          </el-row>
          <el-button type="primary" @click="saveBaseRepairList()">保存</el-button>
        </el-card>
      </el-col>
      <!-- <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>合作公司维修工程师设置</span>
          </div>
          <el-row v-for="(item, index) of companyList" :key="index">
            {{item.companyAbbr}}
            <el-select v-model="item.repairEngineerList" multiple size="mini" style="width: 400px;" :key="'company_' + index">
              <el-option v-for="s in repairEngineerList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
            </el-select>
          </el-row>
          <el-button type="primary" @click="saveCompanyRepairList()">保存</el-button>
        </el-card>
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'repair-basics',
  data () {
    return {
      baseList: [],
      companyList: [],
      repairEngineerList: []
    }
  },
  created () {
    this.queryRepairEngineerData()
    this.queryBaseData()
    this.queryCompanyData()
  },
  methods: {
    /** 获取初始数据 */
    queryBaseData () {
      this.$http({
        url: this.$http.adornUrl('/user/departments'),
        method: 'get',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          this.baseList = data.datas
          //   console.log(this.baseList)
          //   for (let i = 0; i < this.baseList.length; i++) {
          //     if (this.baseList[i].repairEngineers) {
          //       this.baseList[i].repairEnginees = this.baseList[i].split(',')
          //     } else {
          //       this.baseList[i].repairEnginees = []
          //     }
          //   }
          console.log(this.baseList)
        } else {
          console.log(data)
        }
      })
    },
    queryRepairEngineerData () {
      this.$http({
        url: this.$http.adornUrl('/user/repairusers'),
        method: 'get',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          this.repairEngineerList = data.datas
          console.log(this.repairEngineerList)
        } else {
          console.log(data)
        }
      })
    },
    queryCompanyData () {
      this.$http({
        url: this.$http.adornUrl('/company/listpartner'),
        method: 'post',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          this.companyList = data.datas
          console.log(this.companyList)
        } else {
          console.log(data)
        }
      })
    },
    saveBaseRepairList () {
      for (let i = 0; i < this.baseList.length; i++) {
        if (this.baseList[i].repairEngineerList && this.baseList[i].repairEngineerList.length > 0) {
          this.baseList[i].repairEngineers = this.baseList[i].repairEngineerList.join(',')
        } else {
          this.baseList[i].repairEngineers = null
        }
      }
      this.$http({
        url: this.$http.adornUrl('/user/saverepairs'),
        method: 'post',
        data: this.baseList
      }).then((data) => {
        if (data && data.code === 200) {
          this.queryBaseData()
          this.$message.success('保存基地维修工程师信息成功')
        } else {
          this.$message({
            type: 'warning',
            message: '保存基地维修工程师信息失败'
          })
        }
      })
    },
    saveCompanyRepairList () {
      for (let i = 0; i < this.companyList.length; i++) {
        if (this.companyList[i].repairEngineerList && this.companyList[i].repairEngineerList.length > 0) {
          this.companyList[i].repairEngineers = this.companyList[i].repairEngineerList.join(',')
        } else {
          this.companyList[i].repairEngineers = null
        }
      }
      this.$http({
        url: this.$http.adornUrl('/company/saverepairs'),
        method: 'post',
        data: this.companyList
      }).then((data) => {
        if (data && data.code === 200) {
          this.queryCompanyData()
          this.$message.success('保存公司维修工程师信息成功')
        } else {
          this.$message({
            type: 'warning',
            message: '保存公司维修工程师信息失败'
          })
        }
      })
    }
  }
}
</script>
